<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:[`item.car_type`]="{ item }">
        {{ item.car_type == 'CT1' ? 'รย.1' : '' }}
        {{ item.car_type == 'CT2' ? 'รย.2' : '' }}
        {{ item.car_type == 'CT3' ? 'รย.3' : '' }}
        {{ item.car_type == 'CT4' ? 'รย.12' : '' }}
        {{ item.car_type == 'CT13' ? 'รย.17' : '' }}
        {{ item.car_type == 'CT5' ? 'รย.13' : '' }}
        {{ item.car_type == 'CT14' ? 'รย.15' : '' }}
        {{ item.car_type == 'CT6' ? 'ขส.10' : '' }}
        {{ item.car_type == 'CT7' ? 'ขส.30' : '' }}
        {{ item.car_type == 'CT8' ? 'ขส.40' : '' }}
        {{ item.car_type == 'CT9' ? 'ขส.70' : '' }}
        {{ item.car_type == 'CT10' ? 'ขส.80' : '' }}
        {{ item.car_type == 'CT11' ? 'รย.1EV' : '' }}
        {{ item.car_type == 'CT12' ? 'อื่น ๆ' : '' }}
      </template>
      <template v-slot:[`item.tax_filling_date`]="{ item }">
        {{ formatDate(item.tax_data.tax_filling_date) }}
      </template>
      <template v-slot:[`item.is_one_year_extension_tax`]="{ item }">
        <v-icon v-if="item.tax_data.is_one_year_extension_tax">
          mdi-check
        </v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`item.is_original_document`]="{ item }">
        <div v-if="item.is_original_document">ตัวจริง</div>
        <div v-else>สำเนา</div>
      </template>
      <template v-slot:[`item.extension_tax_fee`]="{ item }">
        {{ item.tax_data.extension_tax_fee | numeral('0,0.00') }}
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PendingForm from './PendingForm';
import * as moment from 'moment';

export default {
  components: {
    PendingForm,
  },
  props: [
    'search',
    'carTypeId',
    'updateList',
    'startDate',
    'endDate',
    'date_type',
  ],
  data: () => ({
    dialogForm: false,
    dialogFormData: null,
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    headers: [
      { text: 'ลำดับ', value: '_id', filterable: false },
      { text: 'วันที่', value: 'date', filterable: false },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'ประเภท', value: 'car_type', filterable: false },
      { text: 'วันที่ต่อภาษี', value: 'tax_filling_date', filterable: false },
      {
        text: 'ต่อปีเดียว',
        value: 'is_one_year_extension_tax',
        filterable: false,
      },
      { text: 'เอกสาร', value: 'is_original_document', filterable: false },
      { text: 'ค่าภาษี', value: 'extension_tax_fee', filterable: false },
      { text: 'ไม่สำเร็จ', value: 'reason_on_fail', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
    ],
    isCar: true,
    filter: {
      date_type: 0,
      branch_id: null,
      tracking_type: 0,
      tracking_status: 0,
      tax_renewal_round: '',
      car_type_filter: 0,
      startDate: moment().add(-12, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      item_per_page: 20,
      page: 1,
      start_with: '',
    },
  }),
  mounted() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    reloadComponent() {
      this.getData(); // Reload data
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/get_pending_process`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
          this.totalTax = res.result.total_tax;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
  },
  watch: {
    date_type(nv, ov) {
      if (nv != ov) {
        this.filter.date_type = nv;
        this.getData();
      }
    },
    search(nv, ov) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
      if (nv != ov) {
        this.filter.start_with = nv;
        this.getData();
      }
    }, 1000);
    },
    carTypeId(nv, ov) {
      if (nv != ov) {
        this.filter.car_type_filter = nv;
        this.getData();
      }
    },
    startDate(nv, ov) {
      if (nv != ov) {
        this.filter.start_date = nv;
        this.getData();
      }
    },
    endDate(nv, ov) {
      if (nv != ov) {
        this.filter.end_date = nv;
        this.getData();
      }
    },
    updateList(nv, ov) {
      this.getData();
    },
  },
};
</script>
