<template>
  <v-card class="py-2" elevation="0">
    <v-card-title v-if="item" class="justify-center text-h4 pb-10 primary--text">ผลลัพธ์การต่อภาษี - {{ item.car_plate + " " + item.province }}</v-card-title>
    <v-card-text class="popup-background">
      <v-row v-if="item">
        <v-col cols="6" class="px-5 pb-0 pt-5">
          <v-text-field :value="getDateText(item.tax_data.registration_date)" class="mt-1" label="วันที่จดทะเบียน" outlined dense background-color="white" disabled />
          <v-text-field :value="getDateText(item.tax_data.taxout_date)" label="วันที่ภาษีจะขาด" outlined dense background-color="white" disabled />
          <v-text-field :value="item.customer_phone_number" label="เบอร์โทรลูกค้า" outlined dense background-color="white" disabled />
          <v-text-field :value="item.transport_data ? item.transport_data.transportation_product : '-'" label="งานขนส่ง" outlined dense background-color="white" disabled />
          <v-text-field :value="item.parcel_delivery ? item.parcel_delivery : '-'" label="จัดส่งพัสดุ" outlined dense background-color="white" disabled />
        </v-col>
        <v-col cols="6" class="px-5 pb-0 pt-5">
          <v-textarea class="mt-1 mb-1" v-model="item.note" label="โน๊ต" outlined dense background-color="white" />
          <v-radio-group row v-model="item.is_success">
            <div class="mr-5">ผลลัพธ์ ต่อภาษี :</div>
            <v-radio name="tax_result" label="สำเร็จ" :value="true"></v-radio>
            <v-spacer />
            <v-radio name="tax_result" label="ไม่สำเร็จ" :value="false"></v-radio>
          </v-radio-group>
          <v-textarea v-if="!item.is_success" v-model="item.reason_on_fail" label="หมายเหตุที่ไม่สำเร็จ" outlined dense background-color="white" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">ปิด</v-btn>
      <v-btn class="px-10 mr-15" color="primary" @click="save()" :loading="loading" :disabled="loading">บันทึกข้อมูล</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    close() {
      this.$emit('on-close');
    },
    async save(){
      let postData = {
        process_id: this.item._id,
        is_success: this.item.is_success,
        reason_on_fail: this.item.reason_on_fail,
        note: this.item.note,
      }
      let body = {
        token: this.$jwt.sign(postData, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/process/update_on_process_job`, body)
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        });
      this.close();
    },
    getDateText(date){
      let yearOffset = 543;
      let d = moment(date);
      return d.format('DD/MM') + '/' + (parseInt(d.format('YYYY')) + yearOffset);
    }
  },
  computed: {
    isUpdate() {
      return this.item;
    },
  },
}
</script>

