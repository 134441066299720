<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ติดตามงานต่อภาษี</div>
      
      <div style="width: 300px">
        <v-text-field
          class="ml-10"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา เลขทะเบียน ชื่อลูกค้า"
          outlined
          rounded
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-text-field label="วันที่" prepend-inner-icon="mdi-calendar" outlined rounded dense hide-details></v-text-field> -->
      <template v-if="tab === 0">
        <!-- <DatePicker
          label="วันเริ่มต้น"
          :value="startDate"
          @onChange="
            (val) => {
              startDate = val;
            }
          "
          hideDetails
        />
        <DatePicker
          label="วันสิ้นสุด"
          :value="endDate"
          @onChange="
            (val) => {
              endDate = val;
            }
          "
          hideDetails
        />
        <div style="width: 200px">
          <v-select
            label="ทั้งหมด"
            v-model="carTypeId"
            :items="[
              { text: 'ทั้งหมด', value: 0 },
              { text: 'รถยนต์', value: 1 },
              { text: 'รถจักรยานยนต์', value: 2 },
              { text: 'อื่น ๆ', value: 3 },
            ]"
            item-value="value"
            item-text="text"
            outlined
            rounded
            dense
            hide-details
          />
        </div> -->
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          small
          class="ml-5"
          @click="cancelPendingProcess()"
          :disabled="!selected.pending.length"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="success"
          small
          class="ml-2"
          @click="openDialogPending()"
          :disabled="!selected.pending.length"
        >
          ส่งต่อภาษี
        </v-btn>
      </template>

      <template v-else-if="tab === 1">
        <div class="" v-if="totalTax">
          ยอดรวมภาษี {{ totalTax | numeral('0,0') }} บาท
        </div>
        <v-spacer></v-spacer>
        <div style="width: 200px">
          <v-select
            label="รอบ"
            v-model="taxRenewalRound"
            :items="renewalTaxList"
            item-value="value"
            item-text="text"
            outlined
            rounded
            dense
            hide-details
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          small
          class="ml-5"
          @click="cancelInprogressProcess()"
          :disabled="!selected.inprogress.length"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="success"
          small
          class="ml-2"
          @click="PayTax()"
          :disabled="!selected.inprogress.length"
        >
          จ่ายค่าภาษี
        </v-btn>
        <v-btn
          color="primary"
          small
          class="ml-2"
          @click="printTax()"
          :disabled="taxRenewalRound == '' || taxRenewalRound == 0"
        >
          ดาวน์โหลดใบรวมภาษี <v-icon right>mdi-printer</v-icon>
        </v-btn>
      </template>

      <template v-else-if="tab === 2">
        <v-spacer></v-spacer>
        <v-btn
          color="success" small class="ml-2"
          @click="getBook()"
          :disabled="!selected.complete.length"
        >
          ลูกค้ารับเล่มแล้ว
        </v-btn>
      </template>

      <template v-else-if="tab === 4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          class="ml-2"
          @click="backToPending()"
          :disabled="!selected.cancel.length"
        >
          กลับไปรอดำเนินงาน
        </v-btn>
      </template>
      <template v-else></template>
    </v-toolbar>
    <v-card outlined class="mb-5" v-if="tab === 0"
      ><v-card-text>
        <v-row class="my-1">
          <template v-if="tab === 0">
            <!-- <v-text-field label="วันที่" prepend-inner-icon="mdi-calendar" outlined rounded dense hide-details></v-text-field> -->
            <div style="width: 300px" class="ml-2 my-1">
              <v-select
                label="กรอกจากวัน"
                v-model="date_type"
                :items="[
                  { text: 'วันสร้างบิล', value: 0 },
                  { text: 'วันที่จะยื่นต่อภาษี', value: 1 },
                ]"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
              />
            </div>
            <div style="width: 200px" class="ml-2 my-1">
              <DatePicker
                label="วันเริ่มต้น"
                :value="startDate"
                @onChange="
                  (val) => {
                    startDate = val;
                  }
                "
                hideDetails
                outlined
                rounded
                dense
              />
            </div>
            <div style="width: 200px" class="ml-1 my-1">
              <DatePicker
                label="วันสิ้นสุด"
                :value="endDate"
                @onChange="
                  (val) => {
                    endDate = val;
                  }
                "
                hideDetails
                outlined
                rounded
                dense
              />
            </div>
            <div style="width: 300px" class="ml-2 my-1">
              <v-select
                label="ประเภทรถ"
                v-model="carTypeId"
                :items="[
                  { text: 'ทั้งหมด', value: 0 },
                  { text: 'รถยนต์', value: 1 },
                  { text: 'รถจักรยานยนต์', value: 2 },
                  { text: 'อื่น ๆ', value: 3 },
                ]"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
              />
            </div>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-tabs
        v-model="tab"
        align-with-title
        background-color="white"
        active-class="light black--text"
      >
        <v-tabs-slider color="light"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Pending
            ref="pending"
            :key="'pending_' + tab"
            @on-selected="selected.pending = $event"
            :search="search"
            :startDate="startDate"
            :endDate="endDate"
            :date_type="date_type"
            :car-type-id="carTypeId"
            :update-list="updatePending"
            @reloadParent="reloadPending"
          />
        </v-tab-item>
        <v-tab-item>
          <InProgress
            ref="inprogress"
            :key="'inprogress_' + tab"
            v-model="totalTax"
            @on-selected="selected.inprogress = $event"
            :search="search"
            :tax-renewal-round="taxRenewalRound"
            :update-list="updateInprogress"
          />
        </v-tab-item>
        <v-tab-item>
          <Complete
            ref="complete"
            :key="'complete_' + tab"
            @on-selected="selected.complete = $event"
            :search="search"
          />
        </v-tab-item>
        <v-tab-item>
          <Received
            ref="receive"
            :key="'receive_' + tab"
            @on-selected="selected.received = $event"
            :search="search"
          />
        </v-tab-item>
        <v-tab-item>
          <Cancel
            ref="cancel"
            :key="'cancel_' + tab"
            @on-selected="selected.cancel = $event"
            :search="search"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog persistent scrollable max-width="600" v-model="dialogPendingForm">
      <PendingForm
        ref="pendingForm"
        :item="dialogPendingFormData"
        :process-array="dialogPendingFormProcessArray"
        @onClose="closeDialogPending"
        @onSave="onSaveDialogPending"
        @reloadParent="reloadPending"
      />
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import Pending from './Pending';
import InProgress from './InProgress';
import Complete from './Complete';
import Received from './Received';
import Cancel from './Cancel';
import PendingForm from './PendingForm';
import * as moment from 'moment';

export default {
  components: {
    DatePicker,
    Pending,
    InProgress,
    Complete,
    Received,
    Cancel,
    PendingForm,
  },
  data: () => ({
    dialogPendingForm: false,
    dialogPendingFormData: null,
    dialogPendingFormProcessArray: [],

    renewalTaxList: [],
    carTypeId: 0,
    date_type: 0,
    taxRenewalRound: '',
    startDate: moment().add(-12, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),

    search: '',
    tab: 0,
    items: [
      'รอดำเนินการ',
      'กำลังดำเนินการ',
      'งานเสร็จสมบูรณ์ (รอลูกค้ามารับ)',
      'ลูกค้ารับไปแล้ว',
      'ยกเลิก',
    ],

    selected: {
      pending: [],
      inprogress: [],
      complete: [],
      received: [],
      cancel: [],
    },

    totalTax: null,
    updatePending: 0,
    updateInprogress: 0,
    updateComplete: 0,
    updateCancel: 0,
  }),
  created() {
    this.tab = this.$route.params.tab;
    this.$router.replace({ name: 'follow-tax' }).catch(() => {});
    this.getRenewalTaxList();
  },
  methods: {
    reloadPending() {
      this.$refs.pending.reloadComponent();
    },
    async openDialogPending() {
      this.loading = true;
      this.dialogPendingForm = true;
      this.dialogPendingFormProcessArray = this.$refs.pending.selected.map(
        (x) => x._id
      );
      this.dialogPendingFormData = await this.renewalTax(
        this.dialogPendingFormProcessArray
      );
    },
    closeDialogPending() {
      this.dialogPendingForm = false;
    },
    onSaveDialogPending() {
      this.updatePending++;
      this.$refs.pending.selected.length = 0;
    },
    renewalTax(processArray) {
      return new Promise(async (resolve) => {
        let payload = {
          branch_id: this.$store.state.selected_branch._id,
          process_array: processArray,
        };
        let body = {
          token: this.$jwt.sign(payload, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/process/renewal_tax`, body)
          .then((res) => {
            resolve(res.result);
          })
          .catch((err) => {
            console.log('err', err);
            this.$alertServerError({ title: err.error_message });
          });
      });
    },

    async getRenewalTaxList() {
      let payload = {
        branch_id: this.$store.state.selected_branch._id,
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/renewal_tax_list`, body)
        .then((res) => {
          if (
            res.result &&
            res.result.length &&
            typeof res.result === 'object'
          ) {
            this.renewalTaxList.length = 0;
            this.renewalTaxList.push({ text: 'ทั้งหมด', value: '' });
            for (var i = 0; i < res.result.length; i++) {
              this.renewalTaxList.push({
                value: res.result[i],
                text: res.result[i],
              });
            }
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    PayTax() {
      let process_array = this.$refs.inprogress.selected.map((x) => {
        return {
          process_id: x._id,
          transportation_fee: x.tax_data.transportation_fee,
        };
      });
      this.$router
        .push({
          name: 'follow-tax-paytax',
          params: { process_array: process_array },
        })
        .catch(() => {});
    },
    async printTax() {
      this.loading = true;
      let payload = {
        branch_id: this.$store.state.selected_branch._id,
        tax_renewal_round: this.taxRenewalRound,
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/process/download_car_renewal_tax_summary`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          this.forceFileDownload(
            response,
            'ใบรวมภาษี-' + this.taxRenewalRound + '.xlsx'
          );
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },

    async handleCancelProcess(selectedItems, refName) {
      try {
        const cancelArray = selectedItems.map(item => item.car_plate);
        const processArray = selectedItems.map(item => item._id);
        const result = await this.$alertConfirm({
          title: `ยกเลิก ${cancelArray.length} คัน`,
          html: `<h4>${cancelArray.join(', ')}</h4>`,
        });

        if (result.isConfirmed) {
          const body = {
            token: this.$jwt.sign(
              { process_array: processArray },
              this.$privateKey,
              { noTimestamp: true }
            ),
          };

          const response = await this.$axios.post(`${this.$baseUrl}/process/cancel_process`, body);

          // Reset selections and update the UI
          this.selected[refName] = [];
          this.tab = 4;
          this[`update${refName.charAt(0).toUpperCase() + refName.slice(1)}`]++;
          this.$refs[refName].selected = [];

          // Log success to Sentry
          Sentry.captureMessage(`Successfully cancelled ${refName} process`, {
            level: 'info',
            extra: {
              response: response.data,
              token: body.token,
            },
          });
        }
      } catch (err) {
        console.error(`Error cancelling ${refName} process:`, err);
        Sentry.captureException(err, {
          extra: {
            message: `Error cancelling ${refName} process`,
            token: body?.token,
          }
        });
      }
    },

    // Usage for In-progress Process
    async cancelInprogressProcess() {
      await this.handleCancelProcess(this.$refs.inprogress.selected, 'inprogress');
    },

    // Usage for Pending Process
    async cancelPendingProcess() {
      await this.handleCancelProcess(this.$refs.pending.selected, 'pending');
    },
  
    async getBook() {
      this.$alertConfirm({ title: `ยืนยันลูกค้ามารับเล่ม ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let process_array = this.$refs.complete.selected.map((x) => {
              return x._id;
            });
            let body = {
              token: this.$jwt.sign(
                { process_id: process_array },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/process/record_tax_parcel_book`, body)
              .then((res) => {
                this.selected.complete = [];
                this.tab = 3;
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            this.updateComplete++;
            this.$refs.complete.selected.length = 0;
          }
        }
      );
    },
    async backToPending() {
      try {
        // Confirm action with the user
        const resetArray = this.$refs.cancel.selected.map(item => item.car_plate);
        const result = await this.$alertConfirm({
          title: `ย้ายกลับไป รอดำเนินการ ${resetArray.length} คัน`,
          html: `<h4>${resetArray.join(', ')}</h4>`,
        });

        if (result.isConfirmed) {
          // Map selected items to extract process IDs
          const processArray = this.$refs.cancel.selected.map(item => item._id);

          // Create the request body with a signed token
          const body = {
            token: this.$jwt.sign(
              { process_array: processArray },
              this.$privateKey,
              { noTimestamp: true }
            ),
          };

          // Send the request to reset the process
          const response = await this.$axios.post(`${this.$baseUrl}/process/reset_process`, body);

          // Clear selected items and update the UI
          this.selected.cancel = [];
          this.tab = 0;
          this.updateCancel++;

          // Reset the selected items in the cancel list
          this.$refs.cancel.selected = [];
        }
      } catch (err) {
        // Log the error and alert the user
        console.error('Error resetting process to pending:', err);
        Sentry.captureException(err, {
          extra: {
            message: 'Error resetting process to pending',
            token: body?.token,
          }
        });
        this.$alertServerError({ title: err.error_message });
      }
    },
  },
  watch: {
    tab: function (newVal, oldVal) {
      if (newVal) {
        this.search = '';
        this.taxRenewalRound = '';
      }
    },
  },
};
</script>