var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"sort-by":"calories","loading-text":_vm.$table_loading_text,"page":_vm.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list.map(function(x) {return x._id; }).indexOf(item._id)+1+(_vm.filter.item_per_page*(_vm.filter.page-1)))+" ")]}},{key:"item.pick_up_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.pick_up_date))+" ")]}},{key:"item.pick_up_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pick_up_time)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"grey","small":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v("ดู")])]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600"},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('ReceivedForm',{attrs:{"item":_vm.dialogFormData},on:{"onClose":function($event){_vm.dialogForm = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }