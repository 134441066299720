var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"sort-by":"calories","loading-text":_vm.$table_loading_text,"page":_vm.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","show-select":"","item-key":"_id"},on:{"update:page":function($event){_vm.page=$event},"input":function($event){return _vm.$emit('on-selected', _vm.selected)}},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list .map(function (x) { return x._id; }) .indexOf(item._id) + 1 + _vm.filter.item_per_page * (_vm.filter.page - 1))+" ")]}},{key:"item.car_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.car_type == 'CT1' ? 'รย.1' : '')+" "+_vm._s(item.car_type == 'CT2' ? 'รย.2' : '')+" "+_vm._s(item.car_type == 'CT3' ? 'รย.3' : '')+" "+_vm._s(item.car_type == 'CT4' ? 'รย.12' : '')+" "+_vm._s(item.car_type == 'CT13' ? 'รย.17' : '')+" "+_vm._s(item.car_type == 'CT5' ? 'รย.13' : '')+" "+_vm._s(item.car_type == 'CT14' ? 'รย.15' : '')+" "+_vm._s(item.car_type == 'CT6' ? 'ขส.10' : '')+" "+_vm._s(item.car_type == 'CT7' ? 'ขส.30' : '')+" "+_vm._s(item.car_type == 'CT8' ? 'ขส.40' : '')+" "+_vm._s(item.car_type == 'CT9' ? 'ขส.70' : '')+" "+_vm._s(item.car_type == 'CT10' ? 'ขส.80' : '')+" "+_vm._s(item.car_type == 'CT11' ? 'รย.1EV' : '')+" "+_vm._s(item.car_type == 'CT12' ? 'อื่น ๆ' : '')+" ")]}},{key:"item.is_original_document",fn:function(ref){
var item = ref.item;
return [(item.is_original_document)?_c('div',[_vm._v("ตัวจริง")]):_c('div',[_vm._v("สำเนา")])]}},{key:"item.tax_filling_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tax_data.tax_filling_date))+" ")]}},{key:"item.extension_tax_fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.tax_data.extension_tax_fee,'0,0.00'))+" ")]}},{key:"item.transportation_fee",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.tax_data.transportation_fee,"type":"number","outlined":"","dense":"","background-color":"white","single-line":"","hide-details":""},on:{"blur":function($event){return _vm.saveTransportationFee(item)}},model:{value:(item.tax_data.transportation_fee),callback:function ($$v) {_vm.$set(item.tax_data, "transportation_fee", $$v)},expression:"item.tax_data.transportation_fee"}})]}},{key:"item.tax_round",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tax_data.tax_round)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"grey","small":"","outlined":"","rounded":""},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v(" ดู ")])]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filter, "item_per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filter, "item_per_page", $event)},"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"900"},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('InProgressForm',{attrs:{"item":_vm.dialogFormData},on:{"on-close":_vm.closeDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }