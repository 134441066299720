var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.sms_setting.sms_service ? _vm.headers_with_sms : _vm.headers,"items":_vm.list,"loading":_vm.loading,"sort-by":"calories","loading-text":_vm.$table_loading_text,"page":_vm.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":"","show-select":"","item-key":"_id"},on:{"update:page":function($event){_vm.page=$event},"input":function($event){return _vm.$emit('on-selected', _vm.selected)}},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list .map(function (x) { return x._id; }) .indexOf(item._id) + 1 + _vm.filter.item_per_page * (_vm.filter.page - 1))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.parcel_delivery",fn:function(ref){
var item = ref.item;
return [(item.parcel_delivery)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])]}},{key:"item.transportation_product",fn:function(ref){
var item = ref.item;
return [(item.tax_data.transportation_product.length > 0)?_c('v-icon',[_vm._v(" mdi-check ")]):_c('v-icon',[_vm._v("mdi-close")])]}},{key:"item.tel_alert",fn:function(ref){
var item = ref.item;
return [(item.tax_data.is_call_alert)?_c('div',[(item.tax_data.tel_alert == '0')?_c('v-btn',{attrs:{"color":"error","small":"","outlined":""},on:{"click":function($event){return _vm.updateStatus(item._id, 1)}}},[_vm._v(" ต้องการให้โทร ")]):_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.updateStatus(item._id, 1)}}},[_vm._v(" โทรแล้ว "+_vm._s(item.tax_data.tel_alert)+" ครั้ง ")])],1):_vm._e(),(!item.tax_data.is_call_alert)?_c('div',[_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""}},[_vm._v("ไม่ต้อง")])],1):_vm._e()]}},{key:"item.pick_up_date",fn:function(ref){
var item = ref.item;
return [(item.tracking_status != 3)?_c('v-btn',{attrs:{"color":"success","small":"","outlined":""},on:{"click":function($event){return _vm.openReceivedDialog(item._id)}}},[_vm._v(" รับเล่มคลิก ")]):_c('v-btn',{attrs:{"color":"success","small":""}},[_vm._v("รับเล่มแล้ว")])]}},{key:"item.margin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"red--text"},[_vm._v(_vm._s(item.tax_data.margin))])]}},{key:"item.margin_minus",fn:function(ref){
var item = ref.item;
return [(item.margin_minus)?_c('div',{staticClass:"red--text text-center"},[_vm._v(" "+_vm._s(_vm._f("numeral")(item.margin_minus,'0,0.00'))+" ")]):_c('div',{staticClass:"text-center"},[_vm._v("-")])]}},{key:"item.margin_plus",fn:function(ref){
var item = ref.item;
return [(item.margin_plus)?_c('div',{staticClass:"success--text text-center"},[_vm._v(" "+_vm._s(_vm._f("numeral")(item.margin_plus,'0,0.00'))+" ")]):_c('div',{staticClass:"text-center"},[_vm._v("-")])]}},{key:"item.customer_phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.phoneNumberFormat(item.customer_phone_number))+" ")]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filter, "item_per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filter, "item_per_page", $event)},"update:length":function($event){_vm.pageCount=$event},"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"800"},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('CompleteForm',{attrs:{"item":_vm.dialogFormData},on:{"onClose":function($event){(_vm.dialogForm = false), _vm.getData()}}})],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"800"},model:{value:(_vm.dialogReceivedForm),callback:function ($$v) {_vm.dialogReceivedForm=$$v},expression:"dialogReceivedForm"}},[_c('ReceivedForm',{attrs:{"item":_vm.dialogReceivedFormData},on:{"onClose":function($event){(_vm.dialogReceivedForm = false), _vm.getData()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }